import MKButton from './MKButton'
import styled from '@emotion/styled'

function ConnectedButton({ address }) {
  return <ButtonStyle title={address}>{formatAddress(address)}</ButtonStyle>
}

function formatAddress(address) {
  return `${address.substring(0, 6)}..${address.slice(-4)}`
}

const ButtonStyle = styled(MKButton)({
  border: '1px solid rgba(30,30,30,0.2)',
  boxShadow: 'none',
  color: 'white',
  backgroundColor: 'rgba(255,255,255,0.1)',
  textTransform: 'none',

  ':hover, :active': {
    backgroundColor: 'rgba(255,255,255,0.3)',
    boxShadow: 'none',
  },
})

export default ConnectedButton
