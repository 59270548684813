import development from './development'
import sepolia from './sepolia'

const supportedNetworks = {
    31337: {
        name: 'Development',
        chainId: 31337,
        isTestnet: true,
        contracts: development
    },
    11155111: { 
        name: 'Sepolia',
        chainId: 11155111,
        isTestnet: true,
        contracts: sepolia
    }
}

export function getNetworkInfo(chainId) {
    if (!isChainIdSupported(chainId))
        throw new Error(`Unsupported chainId: ${chainId}`)

    return supportedNetworks[chainId]
}

export function getSupportedNetworks() {
    return Object.values(supportedNetworks)
}

export function isChainIdSupported(chainId) {
    return Boolean(supportedNetworks[chainId])
}