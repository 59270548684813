import styled from '@emotion/styled'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import { useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import { useWeb3React } from '@web3-react/core'

import breakpoints from '../breakpoints'
import ConnectedButton from './ConnectedButton'
import { injected } from '../connectors'
import MKBox from './MKBox'
import MKButton from './MKButton'
import MKTypography from './MKTypography'
import logo from '../images/logo.svg'

export default function Header() {
  const { account, activate, active } = useWeb3React()
  const location = useLocation()

  const connect = useCallback(async () => {
    await activate(injected)
  }, [activate])

  return (
    <MKBox component="nav" position="absolute" top="0.5rem" width="100%">
      <Container>
        <Grid container flexDirection="row" alignItems="center">
          <MKTypography
            component={Link}
            href="#/"
            variant="button"
            color="white"
            fontWeight="regular"
            py={0.8125}
            mr={2}
          >
            <Logo src={logo} />
          </MKTypography>

          <MKBox component="ul" display="flex" p={0} my={0} mx="auto" sx={{ listStyle: 'none' }}>
            <div></div>
          </MKBox>
          <MKBox component="ul" display="flex" p={0} m={0} sx={{ listStyle: 'none' }}>
            {location?.pathname !== '/mint' && <MenuLink href="#/mint">Mint</MenuLink>}
            {location?.pathname !== '/addresses' && (
              <MenuLink href="#/addresses">My Addresses</MenuLink>
            )}
            {active ? (
              <ConnectedButton address={account} />
            ) : (
              <ConnectButton onClick={connect}>Connect</ConnectButton>
            )}
          </MKBox>
        </Grid>
      </Container>
    </MKBox>
  )
}

const ConnectButton = styled(MKButton)({
  border: '1px solid rgba(30,30,30,0.2)',
  boxShadow: 'none',
  color: 'white',
  backgroundColor: 'rgba(255,255,255,0.1)',

  ':hover, :active': {
    backgroundColor: 'rgba(255,255,255,0.3)',
    boxShadow: 'none',
  },
})

const Logo = styled.img({
  width: 42,
  height: 'auto',
})

const MenuLink = styled.a({
  color: 'white',
  opacity: 0.9,
  lineHeight: '36px',
  transition: 'opacity 0.25s ease-out',

  [`@media ${breakpoints.up.xs}`]: {
    marginRight: 10,
    fontSize: '17px',
  },
  [`@media ${breakpoints.up.sm}`]: {
    marginRight: 30,
    fontSize: '18px',
  },

  ':hover, :active': {
    opacity: 1,
  },
})
