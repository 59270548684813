import { useState } from 'react'
import styled from '@emotion/styled'
import Tooltip from '@mui/material/Tooltip'

export default function DifficultyTag({ difficulty }) {
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const { color, content } = getTagParams(difficulty)

  return (
    <LightTooltip
      open={tooltipOpen}
      onClose={() => setTooltipOpen(false)}
      title={
        <>
          On average <strong>{getDifficultyIterations(difficulty)} iterations</strong> are needed to
          generate an address.
        </>
      }
    >
      <Tag
        onMouseEnter={() => setTooltipOpen(true)}
        onClick={() => setTooltipOpen(true)}
        style={{ backgroundColor: color }}
      >
        {content}
      </Tag>
    </LightTooltip>
  )
}

function getDifficultyIterations(diff) {
  switch (diff) {
    case 4:
      return '113'
    case 5:
      return '1900'
    case 6:
      return '31 000'
    case 7:
      return '500 000'
    case 8:
      return '8.4 million'
    case 9:
      return '139 million'
    case 10:
      return '2.3 billion'
    case 11:
      return '38 billion'
    case 12:
      return '628 billion'
    case 13:
      return '10.4 trillion'
    case 14:
      return '173 trillion'
    case 15:
      return '2.9 quadrillion'
    case 16:
      return '48 quadrillion'
    case 17:
      return '802 quadrillion'
    case 18:
      return '13.4 quintillion'
    case 19:
      return '225 quintillion'
    case 20:
      return '3.8 sextillion'
  }
}

function getTagParams(diff) {
  if (diff < 10) return { color: '#317c64', content: 'EASY' }
  else if (diff < 12) return { color: '#98ab48', content: 'MODERATE' }
  else return { color: '#991439', content: 'DIFFICULT' }
}

const Tag = styled.div({
  display: 'flex',
  height: '25px',
  flexBasis: 'auto',
  color: 'white',
  background: 'rgb(19,94,56)',
  borderRadius: '8px',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: '1px 1px 1px 0px rgba(0, 0, 0, 0)',
  fontSize: '0.8rem',
  fontWeight: '600',
  transition: 'background 0.35s ease-out',
  padding: '2px 15px 0 15px',
  cursor: 'pointer',
})

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  '& .MuiTooltip-tooltip': {
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.87)',
    width: '400px',
  },
  '& .MuiTooltip-arrow': {
    color: 'white',
  },
}))
